<template>
	<el-form :label-width="$root.rwd < 3 ? '130px' : ''" :label-position="$root.rwd > 2 ? 'top' : 'left'">
		<el-form-item label="帳號" class="underline">
			{{ruleForm.account}}
		</el-form-item>
		<el-form-item label="信箱" class="underline">
			{{ruleForm.email}}
		</el-form-item>
		<el-form-item label="姓名" class="underline">
			{{ruleForm.name}}{{"\u3000"}}<small class="theme_text">{{ruleForm.gender}}</small>
		</el-form-item>
		<el-form-item label="出生年" class="underline">
			{{ruleForm.birthday | yearFilter}}
		</el-form-item>
		<el-form-item label="手機號碼" class="underline">
			{{ruleForm.cellphone}}
		</el-form-item>
		<el-form-item label="聯絡電話" class="underline">
			{{ruleForm.phone}}
		</el-form-item>
		<el-form-item label="通訊地址" class="underline">
			{{ruleForm.zipcode}}{{"\xa0"}}{{ruleForm.city}}{{ruleForm.district}}{{ruleForm.address}}
		</el-form-item>
	</el-form>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState("auth", ["_auth"]),
		ruleForm() {
			return this._auth || new Object;
		}
	},
}
</script>